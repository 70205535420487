<template>
  <div>
    <h1 v-if="$route.meta.title" class="mb-4">{{title}}</h1>
    <ApplicationInfo v-if="current" :current="current" />
    <ApplicationDetail
        v-if="current"
        :id="applicationId"
        :state="state"
        :values="current.values"
        :info="current.info"
    />
    <SectionCard label="История согласования">
        <TimeLine :items="current ? current.approvalHistory : []" />
    </SectionCard>
  </div>
</template>

<script>
import { get } from 'lodash-es';
import { mapActions, mapGetters } from 'vuex';
import ApplicationInfo from '@/components/spa/ApplicationInfo/ApplicationInfo';
import ApplicationDetail from '@/components/spa/ApplicationDetail/ApplicationDetail';
import TimeLine from '@/components/spa/ApplicationDetail/components/TimeLine';
import SectionCard from '@/components/spa/SectionCard/SectionCard.vue';
import { ROLES } from '@/store/spa/enums';

export default {
  name: 'ApplicationDetailView',
  components: {
    ApplicationInfo,
    ApplicationDetail,
    TimeLine,
    SectionCard,
  },
  props: {
    id: { type: String },
    code: { type: String },
    page: { type: Number, default: 1 },
    size: { type: Number, default: 20 },
    filter: { type: Object }
  },
  computed: {
    ...mapGetters({
      hasRole: 'user/hasRole',
      pending: 'spa/pending',
      current: 'spa/application',
    }),
    state() {
      return get(this.current, 'state');
    },
    isReviewer() {
      return this.hasRole(ROLES.REVIEWER, 'spa');
    },
    title() {
      if (this.id) return `${this.$route.meta.title} #${this.id}`;
      return 'Заявка на доступ';
    },
    applicationId() {
      return this.id || get(this, 'current.id', '');
    }
  },
  methods: {
    ...mapActions({
      fetchApplicationDetail: 'spa/fetchApplicationDetail',
    }),
    onClose() {
      const id = this.id;
      this.fetchApplicationDetail({ id });
    }
  },
  watch: {
    id: {
      immediate: true,
      handler(id) {
        this.fetchApplicationDetail({ id });
      }
    }
  }
}
</script>

<style module lang="scss">

</style>
