<template>
  <div :class="$style.root">
    <VRow>
      <VCol cols="12" md="3">
        <div :class="$style.title" v-html="label" />
      </VCol>
      <VCol cols="12" md="9">
        <template v-if="readonly">
          <template v-if="values && values.length">
            <VRow dense>
              <VCol cols="12" v-for="(val, i) in values" :key="i">
                <template v-if="isPhone">
                  <StaticField :value="val | VMask('+7 (###) ### ## ##')" />
                </template>
                <template v-else-if="isBool">
                  <StaticField :value="val ? 'Да': 'Нет'" />
                </template>
                <template v-else>
                  <StaticField :value="val" :items="options" />
                </template>
              </VCol>
            </VRow>
          </template>
          <template v-else>
            <StaticField />
          </template>
        </template>
        <template v-else>
          <template v-if="options.length || isUser">
            <template v-if="multiple">
              <VAutocomplete :value="values" @input="values => $emit('update:values', values)" multiple :rules="rules" :search-input.sync="search" :items="items" outlined dense auto-grow rows="1" hideDetails="auto" />
            </template>
            <template v-else>
              <VAutocomplete :value="values[0]" @input="(value) => $emit('update:values', insert(values, 0, value))" :rules="rules" :search-input.sync="search" :items="items" outlined dense auto-grow rows="1" hideDetails="auto" />
            </template>
          </template>
          <template v-else>
            <template v-if="isPhone">
              <VTextField :value="values[0]" @input="(value) => $emit('update:values', insert(values, 0, value))" :rules="[...rules, ...phone]" v-mask="'+7 (###) ### ## ##'" outlined dense auto-grow rows="1" hideDetails="auto" />
            </template>
            <template v-else-if="isInn">
              <VTextField :value="values[0]" @input="(value) => $emit('update:values', insert(values, 0, value))" :rules="[...rules, ...inn]" v-mask="'############'" outlined dense auto-grow rows="1" hideDetails="auto" />
            </template>
            <template v-else-if="isEmail">
              <VTextField :value="values[0]" @input="(value) => $emit('update:values', insert(values, 0, value))" :rules="[...rules, ...email]" outlined dense auto-grow rows="1" hideDetails="auto" />
            </template>
            <template v-else-if="isBool">
              <VCheckbox :input-value="values[0]" @change="(value) => $emit('update:values', [value])" :rules="[...rules]" class="mt-0 pt-0" auto-grow rows="1" hideDetails="auto" />
            </template>
            <template v-else>
              <VTextarea :value="values[0]" @input="(value) => $emit('update:values', insert(values, 0, value))" :rules="rules" outlined dense auto-grow rows="1" hideDetails="auto" />
            </template>
          </template>
        </template>
        <div v-if="!!hint" :class="[$style.hint, 'error--text']" v-html="hint" />
      </VCol>
    </VRow>
  </div>
</template>

<script>
import { concat, map } from 'lodash-es';
import { mapActions, mapGetters } from 'vuex';
import StaticField from '@/components/general/StaticField/StaticField';
export default {
  name: 'ApplicationValues',
  components: {
    StaticField,
  },
  props: {
    application: { type: String, required: true },
    code: { type: String, required: true },
    readonly: { type: Boolean },
    multiple: { type: Boolean },
    showComments: { type: Boolean },
    label: { type: String },
    options: { type: Array, default: () => [] },
    values: { type: Array },
    rules: { type: Array },
    hint: { type: String },
  },
  data() {
    return {
      search: '',
      suggestions: [],
      phone: [
        (value) => /[+7] \([\d]{3}\) [\d]{3} [\d]{2} [\d]{2}/.test(value) || 'Укажите корректный телефон',
      ],
      email: [
        (value) => /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(value) || 'Укажите корректный email',
      ],
      inn: [
        (value) => /^\d{10}(\d{2})?$/.test(value) || 'Укажите корректный ИНН',
      ]
    }
  },
  computed: {
    ...mapGetters({
      pending: 'inspect/pending',
    }),
    isBool() {
      return ~['isInternalInspection', 'hasApprovingForPersonalInfo'].indexOf(this.code);
    },
    isPhone() {
      return ~['productSupplyPhone'].indexOf(this.code);
    },
    isInn() {
      return ~['productSupplyInn'].indexOf(this.code);
    },
    isEmail() {
      return ~['productSupplyEmail'].indexOf(this.code);
    },
    isUser() {
      return ~['inspector'].indexOf(this.code);
    },
    items() {
      return concat(this.options, this.suggestions);
    }
  },
  methods: {
    ...mapActions({
      getUserByEmail: 'inspect/getUserByEmail',
    }),
    insert(arr, index, value) {
      const result = [...arr];
      result[index] = value;
      return result;
    },
    toComment() {
      const code = this.code;
      const application = this.application;
      this.$router.push({ name: 'inspect/ApplicationCommentsView', params: { application, code }})
    }
  },
  watch: {
    search(value) {
      const code = this.code;
      if (this.isUser && value && code) {
        const email = value;
        this.getUserByEmail({ email, code }).then((items) => {
          this.suggestions = map(items, ({ id, email}) => ({
            text: email,
            value: id,
          }))
        });
      }
    }
  },
}
</script>

<style module lang="scss">
.root {
  padding: 10px 16px;
  margin: 0 -16px;
}
.title {
  font-weight: bold;
  font-size: 14px;
  color: #212121;
}
.control {
  float: right;
  min-height: 40px;
  margin-left: 10px;
  position: relative;
  z-index: 1;
}
.hint {
  font-style: italic;
  font-size: 13px;
}
</style>
