<template>
  <SectionCard label="Информация о заявке">
    <VRow>
      <VCol v-for="{label, value, options, hint} in information" cols="12" md="6" :key="label">
        <StaticField :label="label" :value="value" :items="options" />
        <small v-if="!!hint" class="error--text" v-text="hint" />
      </VCol>
    </VRow>
  </SectionCard>
</template>

<script>
import { get } from 'lodash-es';
import StaticField from '@/components/general/StaticField/StaticField';
import SectionCard from '@/components/spa/SectionCard/SectionCard';

export default {
  name: 'ApplicationInfo',
  components: {
    StaticField,
    SectionCard,
  },
  props: {
    current: { type: Object }
  },
  computed: {
    information() {
      return [
        {label: 'Статус заявки', value: get(this.current, 'info.status.name'),},
        {label: 'ИНН', value: get(this.current, 'info.inn'),},
        {label: 'ОГРН', value: get(this.current, 'info.ogrn'),},
        {label: 'Организация', value: get(this.current, 'info.name'),},
      ]
    },
  }
}
</script>

<style module lang="scss">

</style>
